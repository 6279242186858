<template>
    <!-- Begin Footer Area -->
    <footer class="footer-style-4" data-bg-image="" style="background-color: #292929;">
        <div class="footer-upper-top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="footer-upper-top-item">
                            <router-link to="/salentoTruck" class="footer-logo">
                                <img src="images/logo/stWhite.png" style="max-height:150px" alt="Salento Truck">
                            </router-link>
                            <div class="footer-social-link">
                                <ul>
                                    <li v-for="(social, index) in socials" :key="index">
                                        <a :href="social.link">
                                            <i v-bind:class="social.iconName"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!--<div class="footer-btn-wrap">
                                <span class="title">Download app:</span>
                                <router-link to="/contact">
                                    <img src="images/button/8-1-179x62.png" alt="Button">
                                </router-link>
                                <router-link to="/contact">
                                    <img src="images/button/8-2-176x59.png" alt="Button">
                                </router-link>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 d-none d-lg-block">
                        <div class="footer-item mt-0">
                            <div class="footer-list contact-list">
                                <h2 class="title">Contact Us</h2>
                                <ul>
                                    <li>
                                        <i class="icofont-envelope-open"></i>
                                        <a href="mailto://info@i-future.com">info@i-future.it</a>
                                    </li>
                                    <li>
                                        <i class="icofont-google-map"></i>
                                        <span> Via Prov.le per Supersano <br> Casarano(LE), 73042</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 d-block d-lg-none">
                        <div class="footer-item-wrap row">
                            <div class="col-sm-6">
                                <div class="footer-item mt-0">
                                    <div class="footer-list contact-list">
                                        <h2 class="title">Contact Us</h2>
                                        <ul>
                                            <li>
                                                <i class="icofont-envelope-open"></i>
                                                <a href="mailto://info@i-future.com">info@i-future.it</a>
                                            </li>
                                            <li>
                                                <i class="icofont-google-map"></i>
                                                <span> Via Prov.le per Supersano <br> Casarano(LE), 73042</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="footer-item mt-sm-0">
                                    <div class="footer-list">
                                        <h2 class="title">General</h2>
                                        <ul>
                                            <li><a href="#faqSection">FAQ</a></li>
                                            <li><a href="#contact">Contact Us</a></li>
                                            <!--<li><router-link to="/contact">Term & Condition</router-link></li>-->
                                            <!--<li><router-link to="/contact">Privacy & Policy</router-link></li>-->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="footer-item-wrap row">
                            <div class="col-lg-3 d-none d-lg-block">
                                <div class="footer-item footer-ps-xl-30">
                                    <div class="footer-list">
                                        <h2 class="title">General</h2>
                                        <ul>
                                            <li><a href="#faqSection">FAQ</a></li>
                                            <li><a href="#contact">Contact Us</a></li>
                                            <!--<li><router-link to="/contact">Term & Condition</router-link></li>-->
                                            <!--<li><router-link to="/contact">Privacy & Policy</router-link></li>-->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-4">
                                <div class="footer-item footer-ps-xl-30">
                                    <div class="footer-list">
                                        <h2 class="title">Attività</h2>
                                        <ul>
                                            <li><a href="doc/Best_Truck.pdf" target="_blank">Best Truck</a></li>
                                            <li><a href="doc/Grand_Prix.pdf" target="_blank">Grand Prix</a></li>
                                            <li><a href="doc/Truck_Pulling.pdf" target="_blank">Truck Pulling</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-4">
                                <div class="footer-item footer-ps-xl-30">
                                    <div class="footer-list">
                                        <h2 class="title">Event</h2>
                                        <ul>
                                            <li><a href="doc/Mappa.pdf" target="_blank">Mappa dell'evento</a></li>
                                            <!--<li><router-link to="/contact">Scaletta eventi</router-link></li>-->
                                            <li><router-link to="/iscrizioni">Iscrizione Gare</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-4">
                                <div class="footer-item">
                                    <div class="footer-list">
                                        <h2 class="title">Join Us</h2>
                                        <ul>
                                            <li><a href="mailto:partner@i-future.com">Diventa espositore</a></li>
                                            <li> <a href="mailto:partner@i-future.com">Diventa food tracker</a></li>
                                            <li><a href="mailto:partner@i-future.com">Diventa Sponsor</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer-bottom-item">
                            <div class="footer-copyright white-text">
                                <span>Powered <i class="icofont-code-alt" style="color:black;"></i> by
                                <a href="https://www.theginlabs.it" style="color:" rel="noopener" target="_blank"><img src="/images/footer/inner-bg/TGL.png"></a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer Area End Here -->

    <!-- Begin Scroll To Top -->
    <back-to-top bottom="50px" right="50px">
        <button type="button" class="footer-scroll-top scroll-to-top style-4 show border-0">
            <i class="icofont-arrow-up"></i>
        </button>
    </back-to-top>
    <!-- Scroll To Top End Here -->
</template>

<script>
export default {
    
  data () {
    return {
      footerBg: 'images/footer/bg/footer.png',
      socials: [
        {
          link: 'https://www.facebook.com/salentotruck',
          iconName: 'icofont-facebook'
        },
        {
          link: 'https://instagram.com/salento_truck?igshid=YmMyMTA2M2Y=',
          iconName: 'icofont-instagram'
        },
        {
          link: 'https://www.youtube.com/channel/UCZkUtG354G9JqYgyfCopQQw',
          iconName: 'icofont-youtube'
        }
      ]
    }
  }
}
</script>
