<template>
    <!-- Begin Main Header Area -->
    <header class="header">
        <div class="header-area header-position-absolute header-sticky header-style-3" :class="{'is-active': isSticky}">
            <div class="container header-container">
                <div class="row align-items-center">
                    <div class="col-xl-3 col-lg-2 col-6">
                        <router-link class="header-logo" to="/">
                            <img class="primary-img" src="images/logo/st.png" style="max-height:80px" alt="Header Logo">
                            <img class="sticky-img" src="images/logo/st.png" style="max-height:80px" alt="Header Logo">
                        </router-link>
                    </div>
                    <div class="col-lg-6 d-none d-lg-block">
                        <div class="header-menu text-lg-center">
                            <nav class="header-menu-nav onepage-nav">
                                <ul>
                                    <li class="header-drop-holder">
                                        <router-link to="/salentotruck">Home</router-link>
                                    </li>
                                    <li class="active">
                                        <a href="#event">Eventi</a>
                                    </li>
                                    <li>
                                        <a href="#sponsor">Partner</a>
                                    </li>
                                    <li class="header-drop-holder">
                                        <a >Attività</a>
                                        <ul class="header-drop-menu">
                                            <li>
                                                <a href="#competition">Competizioni</a>
                                            </li>
                                        </ul>
                                        
                                    </li>
                                    <li>
                                        <a href="#contact">Contattaci</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-6">
                        <ul class="header-right">
                            <li class="d-flex d-lg-none">
                                <button class="btn p-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                                    <i class="icofont-navigation-menu"></i>
                                </button>
                            </li>
                            <li class="setting-btn-wrap d-block d-md-none">
                                <button class="setting-btn bg-transparent" v-on:click="isHidden = !isHidden">
                                    <i class="icofont-ui-settings"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions">
            <div class="offcanvas-header">
                <router-link to="/" class="header-logo">
                    <img src="images/logo/logoIFTLite.png" alt="Header Logo">
                </router-link>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <div class="header-offcanvas-menu">
                    <nav class="header-offcanvas-nav onepage-offcanvas-nav">
                        <ul>
                            <li>
                                <router-link to="/salentotruck">Home</router-link>       
                            </li>
                            <li class="">
                                <a href="#feature">Eventi</a>
                            </li>
                            <li>
                                <a href="#sponsor">Partner</a>
                            </li>
                            <li class="header-drop-holder">
                                <a >Attività</a>
                                <ul class="header-drop-menu">
                                    <li>
                                        <a href="#competition">Competizioni</a>
                                    </li>
                                </ul>
                                
                            </li>
                            <li>
                                <a href="#contact">Contattaci</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- Main Header Area End Here -->
</template>
<script>
export default {
    
  data () {
    return {
      isSticky: false,
      isHidden: false
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      const scrollPos = window.scrollY
      if (scrollPos >= 200) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    })
  }
}
</script>

<style lang="scss">
.header{
    &.fixed-top {
        z-index: 99;
    }
}
.header-toggler {
    background: transparent;
    border: 0px;
    color: #fff;
    font-size: 26px;
    &:hover {
        color: #f1a949;
    }
}
</style>
