<template>
    <!-- Begin Hero Area -->
    
    <div class="hero-area hero-bg hero-style-5" data-bg-image="" :style="{backgroundImage: `url(${ bgImage })`}">
        <div class="container hero-container">
            <div class="hero-item">
                <!-- <div class="hero-content white-text ">
                    <div class="d-block d-md-none">
                        <a href="https://www.lotras.it/it/"> <img style=" max-height:100px" src="images/partner/lotras.png" alt="Hero Image"></a>
                        <img style=" max-height:100px" src="images/partner/mp.png" alt="Hero Image">
                        <a href=" https://www.trasportiprimiceri.com/"><img style=" max-height:100px" src="images/partner/primiceri.png" alt="Hero Image"></a>
                    </div>
                    <h1 class="title">Salento Truck IV</h1>
                    <p class="desc">La IV Edizione dell'evento più rombante del salento</p>
                    <div class="d-none d-md-block">
                        <a href="https://www.lotras.it/it/"><img src="images/partner/lotras.png" alt="Hero Image"></a>
                        <img src="images/partner/mp.png" alt="Hero Image">
                        <a href=" https://www.trasportiprimiceri.com/"><img src="images/partner/primiceri.png" alt="Hero Image"></a>
                    </div>
                </div> -->
                <div class="hero-img">
                    <div class="single-img" style="text-align: center;">
                        <img src="images/hero/inner-img/STLine.png" style="width:45rem;" alt="Hero Image">
                    </div>
                    <div>
                        <p class="special-info">TRUCK RADUNO - EXPO - COMPETIZIONI - STREET FOOD - MUSICA</p>
                    </div>
                    <div class="" style="display: flex; align-items: center; justify-content: center">
                        <a href="#event" class="btn rounded-button" style="width: 18rem;">Scopri l'evento</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Hero Area End Here -->
</template>
<script>
export default {
  data () {
    return {
      bgImage: 'images/hero/bg/bg24.jpg',
      
    }
  }
}
</script>
<style>
.rounded-button{
    background-color: #f1a949;
    width:15rem;   /* Giallo */
    color: white;                /* Colore del testo */
    border: none;                /* Rimuove i bordi */
    padding: 10px 20px;          /* Spaziatura interna del bottone */
    border-radius: 10px;         /* Arrotonda gli angoli */
    font-size: 18px; 
    font-weight: bold;            /* Dimensione del testo */
    cursor: pointer;             /* Cambia il cursore al passaggio */
    transition: background-color 0.3s ease; /* Transizione per l'effetto hover */
}

.special-info{
    text-align: center; 
    font-size: 1rem; 
    font-weight: bold; 
    color:white; 
    margin-top:2rem; 
    margin-bottom:2rem;
}

@media (min-width: 768px) {
  .special-info {
    font-size: 2rem; 
  }
}
</style>
